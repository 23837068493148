import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import Sanitize from "../sanitize";

const ListItems = ({ data, slice = 2 }) => {
  const [slicedData, setSlicedData] = useState(
    data.length > slice ? data.slice(0, slice) : data
  );
  const [show, setShow] = useState(false);

  useEffect(() => {
    setSlicedData(data.length > slice ? data.slice(0, slice) : data);

    return () => {
      return false;
    };
  }, [slice, data]);

  const handleShowMore = () => {
    if (!show) {
      setSlicedData(data);
      setShow(true);
    }

    if (show) {
      setSlicedData(data.length > slice ? data.slice(0, slice) : data);
      setShow(false);
    }
  };

  return (
    <div className="bg-white border-gray-200 border px-3 pt-4 my-3 rounded-brand relative">
      {data.length > slice && show === false && (
        <div
          className="absolute bottom-0 left-0 mb-10 w-full h-32"
          style={{
            backgroundImage:
              "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0.27), rgba(255, 255, 255, 0.36), rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.64), rgba(255, 255, 255, 0.73), rgba(255, 255, 255, 0.82), rgba(255, 255, 255, 0.91), rgb(255, 255, 255))",
          }}
        />
      )}
      <ul className="mb-1 md:px-4 md:pt-2">
        {slicedData.map((item, index) => {
          return (
            <li
              className="flex items-top pb-2 mb-2 space-x-3"
              key={"wyl_" + index}
            >
              <span className="icon-check-circle text-brand-primary text-lg leading-snug" />
              <Sanitize html={item} className="text-base text-gray-800" />
            </li>
          );
        })}
        {data.length > slice && (
          <li className="text-base font-medium text-right">
            <button
              onClick={handleShowMore}
              className={`inline-flex items-center`}
            >
              <Trans>See</Trans>{" "}
              {!show ? <Trans>more</Trans> : <Trans>less</Trans>}
              <span
                className={`icon-angle-down-l rotate-icon text-brand-primary text-xl ml-2 ${
                  show && "rotate-icon--opened"
                }`}
              />
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ListItems;
