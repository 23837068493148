import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import classNames from "classnames";
import Rating from "./rating";
import Link from "./link";
import ParagraphShortening from "./paragraphShortening";
import LazyImage from "./lazyImage";
import PricesContext from "~/providers/pricesProvider";
import Loader from "./loader";
import Period from "./transHelpers/period";
import CourseTag from "./courseTag";
import Button from "./button";
import { wpURL } from "~/utils/app-helpers";
import { useLang } from "~/../langProvider.js";

const CourseCardMobile = ({
  ID,
  image,
  tutor,
  title,
  reviews,
  reviews_avg,
  students,
  free,
  promo_acceptable,
  permalink,
  actionFunc,
  exists,
  total_lectures,
  created_at,
  cardWidth,
  auth,
  i18n,
}) => {
  const prices = JSON.stringify(useContext(PricesContext));
  const [price, set_price] = useState("");
  const [price_old, set_price_old] = useState("");
  const [price_type, set_price_type] = useState("");
  const [details, set_details] = useState("");
  const [symbol, set_symbol] = useState("");
  const [courseActive, setCourseActiveStatus] = useState(null);
  const [allCoursesActive, setAllCoursesActiveStatus] = useState(false);

  const lang = useLang();

  useEffect(() => {
    const dec_prices = JSON.parse(prices);

    if (auth.courses.length > 0 && dec_prices !== null && Object.keys(dec_prices).length > 0) {
      const isActive = auth.courses.indexOf(ID);
      const isAllCoursesActive = auth.courses.indexOf(Number(process.env.GATSBY_ALL_COURSES_ID));

      if (isActive === -1) {
        setCourseActiveStatus(false);
      } else {
        setCourseActiveStatus(true);
      }

      if (isActive === -1 && isAllCoursesActive >= 0 && dec_prices[ID].type === "one_time_payment" && promo_acceptable !== "no") {
        setAllCoursesActiveStatus(true);
      } else {
        setAllCoursesActiveStatus(false);
      }
    } else {
      setCourseActiveStatus(false);
    }

    if (dec_prices !== null && dec_prices[ID] !== undefined) {
      set_price(dec_prices[ID].price);
      set_price_old(dec_prices[ID].price_old);
      set_price_type(dec_prices[ID].type);
      set_details(dec_prices[ID].sub_details);
      set_symbol(dec_prices.currency.symbol);
    }

    // console.log(prices, ID)

    return () => {
      return false;
    };
  }, [prices, ID, auth.courses, promo_acceptable]);

  const link = courseActive
    ? `${wpURL(lang)}/learner/my-purchased-courses/?section=course&obj=${ID}&lecture=1&content_type=video`
    : `/course/${permalink.category}/${permalink.slug}/`;

  const cartButton = () => {
    // let addToCart = actionToMake === "ADD" ? true : false;
    let btnClasses = classNames("btn text-center", {
      "btn-primary-reverse": !exists,
      "btn-primary": exists,
    });

    return (
      <button
        className={btnClasses}
        style={{ width: 46, height: 36, padding: 0 }}
        // onClick={
        //   addToCart
        //     ? actionFunc({ image, title, price_old, price })
        //     : actionFunc({ title, price })
        // }
        onClick={actionFunc({ ID, image, title, link })}
        disabled={price === ""}
      >
        <span className="icon-shopping-cart" />
      </button>
    );
  };

  const isBestseller = () => {
    return !free && reviews.count > 20;
  };

  const isNew = () => {
    let at = new Date(created_at);
    let now = new Date();

    let Difference_In_Time = now.getTime() - at.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Difference_In_Days < 45;
  };

  const isDescounted = () => {
    return price_old !== "" && price !== price_old;
  };

  return (
    <Link
      to={link}
      className={`p-3 bg-white rounded-brand shadow-sm border border-gray-200 text-gray-800 no-underline mb-3 flex flex-wrap h-full ${cardWidth}`}
    >
      <div className="flex">
        <LazyImage
          src={`https://res.cloudinary.com/ie1/image/fetch/f_auto,w_129,h_101,c_fill,g_auto,q_auto:best/${image}`}
          lazyBgUrl={`https://res.cloudinary.com/ie1/image/fetch/f_auto,w_1,h_1,q_auto:low/${image}`}
          alt=""
          style={{
            width: 86,
            height: 67,
          }}
          className="rounded-brand"
        />
        <div className="flex-1">
          <div className=" flex justify-between pl-2">
            <div className="text-left font-medium text-under-base leading-snug text-gray-700">
              <ParagraphShortening text={title} howMuch={80} />
            </div>
          </div>
          {reviews.count > 10 ? (
            <div className="relative mt-2 ml-2 text-right">
              <Rating reviews_avg={reviews_avg} className="" />
              <span className="ml-1 align-middle">{reviews.count}</span>
            </div>
          ) : (
            <div className="relative mt-2 ml-2 text-right">
              <span className="icon-file-certificate mr-1" />
              <Trans>Certificate included</Trans>
            </div>
          )}
        </div>
      </div>
      {/* <div className="text-left font-medium text-sm mt-3 leading-snug text-gray-800 flex-1">
        <ParagraphShortening text={title} howMuch={80} />
      </div> */}
      <div className="text-sm mt-2 pt-1 flex justify-between w-full">
        <div className="flex items-center">
          <span className="text-xs text-gray-600">
            <Trans>By</Trans>{" "}
            <span className="font-medium">
              <ParagraphShortening text={tutor.name} />
            </span>
          </span>
        </div>
        <span>
          {students > 10 ? (
            <div className="leading-tight whitespace-nowrap">
              <span className="icon-user mr-1" />
              {students} <Trans>students</Trans>
            </div>
          ) : (
            <>
              <span className="icon-book-open mr-1" />
              {total_lectures} <Trans>lectures</Trans>
            </>
          )}
        </span>
      </div>

      {/* <div className="text-right pb-2 mt-2"></div> */}
      <div className="w-full">
        {courseActive === false && allCoursesActive === false ? (
          <>
            <div className="my-2 text-xs flex items-center justify-between w-full">
              <div className="text-left">
                <span className="">
                  {!free && price !== "" && isDescounted() && (
                    <>
                      <span className="bg-green-200 border border-green-200 p-1 px-2 mr-2 text-green-700 rounded-brand ">
                        {Math.floor(100 - (Number(price) * 100) / Number(price_old))}% OFF
                      </span>
                      <span className="line-through">
                        {price_old} {symbol}
                      </span>
                    </>
                  )}
                  {details !== "" && (
                    <>
                      {details.period_amount !== "" && (
                        <>
                          <span className="bg-green-200 border border-green-200 p-1 px-2 mr-2 text-green-700 rounded-brand uppercase">
                            <Trans>Sale</Trans>
                          </span>
                          <span className="line-through text-gray-600 mr-2">
                            {price} {symbol}
                            {price_type !== "one_time_payment" && (
                              <>
                                {price_type === "monthly_subscription" && (
                                  <span>
                                    {" "}
                                    / <Trans>month</Trans>
                                  </span>
                                )}
                                {price_type === "weekly_subscription" && (
                                  <span>
                                    {" "}
                                    / <Trans>week</Trans>
                                  </span>
                                )}
                              </>
                            )}
                          </span>
                        </>
                      )}
                    </>
                  )}
                </span>
              </div>
              <div>
                {isBestseller() && (
                  <CourseTag style={{ paddingBottom: 6 }}>
                    <Trans>Bestseller</Trans>
                  </CourseTag>
                )}
                {!isBestseller() && isNew() && (
                  <CourseTag style={{ paddingBottom: 6 }}>
                    <Trans>New</Trans>
                  </CourseTag>
                )}
              </div>
            </div>
            <div className="text-base flex justify-between items-center mt-3 self-end w-full">
              <button
                className={`btn btn-primary flex-1 px-1 leading-tight text-center inline-block ${price_type !== "one_time_payment" && "text-sm"}`}
                style={{ width: "100%", height: 36, marginRight: 15 }}
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/checkout", locale);
                // }}
                disabled={price === ""}
              >
                {price === "" ? (
                  <Loader />
                ) : (
                  <>
                    {free ? (
                      <span className="uppercase">
                        <Trans>Free</Trans>
                      </span>
                    ) : (
                      <>
                        {/* <Trans>Buy now</Trans> */}
                        {details !== "" && details.period_amount === "-1" ? (
                          <>
                            {details.period_price === "free" ? (
                              <span className="uppercase">
                                <strong>
                                  <Trans>Free</Trans>
                                </strong>
                              </span>
                            ) : (
                              <>
                                {details.period_price} {symbol} / {details.period === "week" ? <Trans>week</Trans> : <Trans>month</Trans>}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {details !== "" && details.period_amount > 0 ? (
                              <>
                                {details.period_price === "free" ? (
                                  <span className="uppercase">
                                    {details.period_amount} <Period amount={details.period_amount} period={details.period} /> <Trans>Free</Trans>
                                  </span>
                                ) : (
                                  <>
                                    {details.period_price} {symbol} / {details.period === "week" ? <Trans>week</Trans> : <Trans>month</Trans>}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {price} {symbol}
                                {price_type !== "one_time_payment" && (
                                  <>
                                    {price_type === "monthly_subscription" && (
                                      <span>
                                        {" "}
                                        / <Trans>month</Trans>
                                      </span>
                                    )}
                                    {price_type === "weekly_subscription" && (
                                      <span>
                                        {" "}
                                        / <Trans>week</Trans>
                                      </span>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </button>
              {cartButton()}
            </div>
          </>
        ) : (
          <>
            {allCoursesActive ? (
              <Button className="leading-tight flex items-center justify-center space-x-2 mt-2" style={{ height: 36 }}>
                <span className="uppercase">
                  <Trans>Add course</Trans>
                </span>
                <span className="icon-plus text-xl ml-2" />
              </Button>
            ) : (
              <Button className="leading-tight flex items-center justify-center space-x-2 mt-2" style={{ height: 36 }}>
                {courseActive === null ? (
                  <Loader />
                ) : (
                  <>
                    <span className="uppercase">
                      <Trans>View Course</Trans>
                    </span>
                    <span className="icon icon-play-circle-s" />
                  </>
                )}
              </Button>
            )}
          </>
        )}
      </div>
    </Link>
  );
};

CourseCardMobile.propTypes = {
  ID: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  tutor: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  reviews: PropTypes.object.isRequired,
  reviews_avg: PropTypes.number.isRequired,
  students: PropTypes.number.isRequired,
  free: PropTypes.bool.isRequired,
  permalink: PropTypes.object.isRequired,
  actionFunc: PropTypes.func.isRequired,
  // actionToMake: PropTypes.string.isRequired,
  total_lectures: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
};

CourseCardMobile.defaultProps = {
  cardWidth: "w-full",
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps)(CourseCardMobile);
