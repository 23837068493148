import React, { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";

const ButtonAddPro = ({ course_type, promo_acceptable, auth }) => {
  const [showButton, setButtonStatus] = useState(false);

  useEffect(() => {
    setButtonStatus(auth?.email === null && promo_acceptable === "yes" && course_type === "one_time_payment");

    return () => {
      return false;
    };
  }, [course_type, auth, promo_acceptable]);

  if (!showButton) return null;

  return (
    <div className="w-full">
      <a
        href="https://cursos.vtutor.com/pro?utm_source=landingvt"
        className="block btn btn-primary whitespace-nowrap px-1 py-3 w-full text-base mt-2"
      >
        <div className="flex items-center justify-center font-semibold">
          <Trans>Access to this course for 7.99 $ / month</Trans>
        </div>
      </a>
    </div>
  );
};

export default ButtonAddPro;
