import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Carrousel from "~/components/Carrousel";
import CourseCardMobile from "~/components/courseCardMobile";
import { isCourseInCart } from "~/utils/app-helpers";
import * as actionCreators from "~/redux/actions/cart_actions";

const MoreCourses = (props) => {
  const courses = props.data;
  const { loop, enableButtons } = props;

  useEffect(() => {
    return () => {
      return false;
    };
  });

  //   const CarrouselData = {
  //     courses: courses,
  //     cart: props.cart,
  //     removeFromCartAction: props.removeFromCartAction,
  //     addToCartAction: props.addToCartAction,
  //   };

  //   return <Carrousel {...CarrouselData} {...{loop, enableButtons}} />;

  return (
    <div className="grid md:grid-cols-2 gap-1">
      {courses.length > 0 &&
        courses.map((course, idx) => {
          const courseData = course.node;
          const exists = isCourseInCart(courseData, props.cart.products);

          let funcCart = () => (e) => {
            e.preventDefault();
            e.stopPropagation();

            return undefined;
          };

          if (!exists) {
            funcCart = (product) => (e) => {
              e.preventDefault();
              e.stopPropagation();
              props.addToCartAction(product);
            };
          }

          return (
            <CourseCardMobile
              {...courseData}
              actionFunc={funcCart}
              // actionToMake={actionToMake}
              lazy={false}
              locale={props.locale}
              exists={exists}
              key={`id_course_${idx}`}
            />
          );
        })}
    </div>
  );
};

const mapStateToProps = ({ cart }) => {
  return {
    cart,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(actionCreators, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(MoreCourses);
