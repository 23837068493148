import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addCourse } from "~/redux/actions/auth_actions";
import ButtonStateful from "~/components/buttonStateful";
import addCourseService from "~/services/courses/addCourse";

const ButtonAddCourse = ({ course_id, addCourse }) => {
  const [loading, setLoadingStatus] = useState(false);
  const [error, setErrorStatus] = useState(null);

  const addCourseToUser = async () => {
    setLoadingStatus(true);
    setErrorStatus(null);
    const { status, error } = await addCourseService(course_id);

    if (status && status === "success") {
      setTimeout(() => {
        addCourse([course_id]);
      }, 500);
    } else {
      setLoadingStatus(false);
      setErrorStatus(error);
    }
  };

  return (
    <div className="w-full">
      <ButtonStateful className="leading-loose" onClick={addCourseToUser} loading={loading}>
        <div className="flex items-center justify-center font-semibold">
          <Trans>Add course</Trans>
          <span className="icon-plus text-xl ml-2" />
        </div>
      </ButtonStateful>
      {error !== null && <div className="flex-1 text-red-600 mt-1 text-center">{error}</div>}
    </div>
  );
};

ButtonAddCourse.propTypes = {
  course_id: PropTypes.number.isRequired,
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addCourse: addCourse,
    },
    dispatch
  );
};

export default connect(null, mapActionsToProps)(ButtonAddCourse);
