const addCourse = async (course_id) => {
  let error = null;
  let status = "error";

  try {
    const response = await fetch(`${process.env.GATSBY_AUTH_URL}/course`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ course_id }),
    });

    // if (!response.ok) {
    //   throw new Error(response.statusText);
    // }

    return await response.json();
  } catch (e) {
    error = e.message;
  }

  return { status, error };
};

export default addCourse;
