import React, { useState, useEffect } from "react";
import { Trans, Plural } from "@lingui/macro";
import ModalWrapper from "../modalWrapper";

const Item = ({
  lectures,
  title,
  section_duration,
  section_lectures_count,
}) => {
  const [show, setShow] = useState(false);
  const [showModal, setModalState] = useState(false);
  const [loading, setLoadState] = useState(false);
  const [freeVideo, setFreeVideoState] = useState(null);

  const viewFreeVideo = (url) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    const oembed = `https://vimeo.com/api/oembed.json?url=${url}&loop=false&autoplay=true&responsive=true`;
    setModalState(true);
    setLoadState(true);

    fetch(oembed)
      .then((response) => response.json())
      .then((response) => {
        setLoadState(false);
        setFreeVideoState(response.html);
      })
      .catch((err) => {
        setLoadState(false);
        console.error(err);
      });
  };

  useEffect(() => {
    if (typeof window !== "undefined")
      document.body.classList.toggle("overflow-hidden", showModal);

    return () => {
      return false;
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <ModalWrapper
          hideModal={() => {
            setModalState(false);
          }}
          width="95%"
        >
          {loading ? (
            <div className="flex items-center justify-center w-full h-38">
              <Trans>Loading video... Please wait...</Trans>
            </div>
          ) : (
            <>
              {freeVideo !== null && (
                <span dangerouslySetInnerHTML={{ __html: freeVideo }} />
              )}
            </>
          )}
        </ModalWrapper>
      )}
      <div className="bg-white rounded-brand shadow px-2 py-4 flex flex-col mb-3 lg:px-4">
        <button
          className="flex items-start w-full space-x-2 text-left lowercase mb-2"
          onClick={() => setShow(!show)}
        >
          <span
            className={`icon-angle-down-l rotate-icon text-brand-primary text-xl leading-none ${
              show && "rotate-icon--opened"
            }`}
          />
          <span className="font-medium capitalize-letter">{title}</span>
          <div className="text-right flex-auto whitespace-nowrap font-medium space-x-2">
            <table className="w-full">
              <tbody>
                <tr>
                  <td>
                    <Plural
                      value={section_lectures_count}
                      zero="There're no lectures"
                      one="# lecture"
                      other="# lectures"
                    />
                  </td>
                  <td className="w-20 hidden lg:table-cell">
                    {section_duration !== "00:00:00" &&
                      formatDuration(section_duration)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </button>
        {show && (
          <>
            {lectures.map((lecture, key) => {
              return (
                <div
                  className="pb-3 flex flex-col items-start lg:pb-4"
                  key={`Lecture_${key}`}
                >
                  {lecture.video_url ? (
                    <button
                      className="whitespace-nowrap text-brand-primary pl-4 lg:flex lg:w-full"
                      onClick={viewFreeVideo(lecture.video_url)}
                    >
                      <Desc
                        title={lecture.title}
                        video_url={lecture.video_url}
                        lecture_duration={lecture.lecture_duration}
                        resource_types={lecture.resource_types}
                      />
                    </button>
                  ) : (
                    <div className="lg:flex lg:w-full pl-4">
                      <Desc
                        title={lecture.title}
                        video_url={lecture.video_url}
                        lecture_duration={lecture.lecture_duration}
                        resource_types={lecture.resource_types}
                      />
                    </div>
                  )}
                  <div className="hidden lg:block">
                    {lecture.content.description !== "" && (
                      <div className="text-left block text-xs mt-2 ml-10 md:max-w-3/4 md:pr-8 lg:text-base mb-3">
                        {lecture.content.description}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

const Desc = ({ title, video_url, lecture_duration, resource_types }) => {
  return (
    <>
      <div className="text-sm flex items-top leading-tight space-x-2 text-left lg:text-base lowercase">
        <span className="text-lg w-5 text-center">
          {resource_types.length > 1 ? (
            <span className="icon-photo-video" />
          ) : resource_types[0] === "video" ? (
            <span className="icon-play-circle" />
          ) : resource_types[0] === "audio" ? (
            <span className="icon-microphone" />
          ) : resource_types[0] === "file" ? (
            <span className="icon-file" />
          ) : (
            <span className="icon-external-link" />
          )}
        </span>
        <span className="whitespace-normal capitalize-letter">{title}</span>
      </div>
      <div className="hidden lg:flex space-x-2 ml-auto">
        {video_url && (
          <div className="flex items-center mr-2">
            <Trans>Free</Trans>
            <span className="icon-play-circle-s ml-1" />
          </div>
        )}
        {lecture_duration !== "00:00:00" && (
          <>{formatDuration(lecture_duration)}</>
        )}
      </div>
    </>
  );
};

export default Item;

function formatDuration(duration) {
  let array = duration.split(":");

  if (array[0] !== "00") {
    return duration;
  } else {
    return `${array[1]}:${array[2]}`;
  }
}
