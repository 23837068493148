import React from "react";
import PropTypes from "prop-types";
import LazyImage from "./lazyImage";

const Avatar = ({ url, name }) => {

  const isBase64 = url.startsWith("data:image/");

  if (url && !isBase64) {
    return <LazyImage src={url} alt="" className="rounded-full mr-2 bg-gray-400" style={{ height: 36, width: 36 }} />;
  }

  const nameSplitted = name.split(" ");
  const initials = `${nameSplitted[0].charAt(0)}${nameSplitted[nameSplitted.length - 1].charAt(0)}`;

  return (
    <div className="rounded-full mr-2 bg-gray-400 text-white font-semibold flex items-center justify-center text-sm uppercase" style={{ height: 36, width: 36 }}>
      {initials}
    </div>
  );
};

Avatar.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Avatar;
