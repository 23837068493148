import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";

const PrevVideo = ({ url, thumbnail, className }) => {
  const [isLoading, setLoading] = useState(false);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [previewVideoStyles, setPreviewVideoStyles] = useState({
    minHeight: 200,
    minWidth: 260,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(https://res.cloudinary.com/ie1/image/fetch/q_auto:best,f_auto/${thumbnail})`,
  });

  const course_preview_handle = (url) => {
    // console.log("click")
    const oembed = `https://vimeo.com/api/oembed.json?url=${url}&loop=false&autoplay=true&responsive=true`;

    setLoading(true);

    if (previewVideo === null) {
      fetch(oembed)
        .then((res) => res.json())
        .then((response) => {
          setPreviewVideoStyles({ backgroundImage: "none" });
          setPreviewVideo(response.html);
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .finally(() => {
          // always executed
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    // let newStyle = previewVideoStyles;

    // setPreviewVideoStyles({
    //   minHeight: 200,
    //   minWidth: 260,
    //   backgroundSize: "cover",
    //   backgroundRepeat: "no-repeat",
    //   backgroundImage: previewVideo === null ? `url(https://res.cloudinary.com/ie1/image/fetch/q_auto:best,f_auto/${thumbnail})` : "none",
    // });
    // console.log("inicio");

    return () => {
      return false;
    };
  }, []);

  return (
    <div
      className={`flex justify-center items-center h-48 relative w-auto m-4 rounded-brand md:flex-1 ${className}`}
      style={previewVideoStyles}
    >
      {!isLoading ? (
        previewVideo === null ? (
          <div
            className="course-thumbnail"
            onClick={() => course_preview_handle(url)}
          ></div>
        ) : (
          <div className="w-full">
            <span dangerouslySetInnerHTML={{ __html: previewVideo }} />
          </div>
        )
      ) : (
        <div className="w-full h-full flex justify-center items-center course-thumbnail-overlay text-white text-xl rounded-brand">
          <span className="z-10">
            <Trans>Loading Video... Please wait...</Trans>
          </span>
        </div>
      )}
    </div>
  );
};

export default PrevVideo;
