import React from "react";
import PropTypes from "prop-types";

const SocialIcon = ({ social, url, className }) => {
  let icon = null;

  switch (social) {
    case "instagram":
      icon = <span className="icon-instagram-square" />;
      break;
    case "facebook":
      icon = <span className="icon-facebook-square" />;
      break;
    case "twitter":
      icon = <span className="icon-twitter-square" />;
      break;
    case "linkedin":
      icon = <span className="icon-linkedin" />;
      break;
    case "website":
      icon = <span className="icon-browser" />;
      break;
    default:
      break;
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer nofollow" className={className}>
      {icon}
    </a>
  );
};

SocialIcon.propTypes = {
  social: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default SocialIcon;
