import React from "react";
import { Trans } from "@lingui/macro";
import Loader from "~/components/loader";
import Period from "~/components/transHelpers/period";

const ButtonDesktop1 = ({ price, price_type, symbol, handleGoToCheckout, course, details }) => {
  return (
    <button
      className="btn btn-primary-reverse whitespace-nowrap w-2/4 justify-center leading-tigth"
      disabled={price === null}
      onClick={handleGoToCheckout}
      onTouchStart={function () {}}
    >
      {course.free ? (
        <Trans>Join free</Trans>
      ) : (
        <>
          {price === null ? (
            <Loader />
          ) : (
            <>
              {/* <Trans>Buy now</Trans> */}
              {details !== "" && details.period_amount === "-1" ? (
                <>
                  {details.period_price === "free" ? (
                    <span className="">
                      <strong>
                        <Trans>Free</Trans>
                      </strong>
                    </span>
                  ) : (
                    <div className="leading-tight">
                      <span className="text-lg mr-1">
                        <Trans>Join now</Trans>
                      </span>
                      <span className="text-sm">
                        {details.period_price} {symbol} / {details.period === "week" ? <Trans>week</Trans> : <Trans>month</Trans>}
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {details !== "" && details.period_amount > 0 ? (
                    <>
                      {details.period_price === "free" ? (
                        <span className="">
                          <Trans>Get</Trans> {details.period_amount} <Period amount={details.period_amount} period={details.period} />{" "}
                          <Trans>free</Trans>
                        </span>
                      ) : (
                        <span className="leading-tight">
                          <span className="capitalize-letter mr-1">
                            <Trans>Get</Trans> {details.period_amount} <Period amount={details.period_amount} period={details.period} />
                          </span>
                          <span className="text-xs">
                            <Trans>only</Trans> {details.period_price} {symbol}
                          </span>
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      <span>
                        {course.free || price_type !== "one_time_payment" || (price && price.price == 0) ? (
                          <>
                            <Trans>Join now</Trans>
                          </>
                        ) : (
                          <>
                            <Trans>Buy now</Trans>
                          </>
                        )}
                      </span>
                      <span>
                        {price !== null && (
                          <>
                            {price.price > 0 ? (
                              <>
                                &nbsp;{price.price} {symbol}
                              </>
                            ) : (
                              <>
                                , <Trans>its free!</Trans>
                              </>
                            )}
                          </>
                        )}
                        {price_type !== "one_time_payment" && (
                          <>
                            {" "}
                            {price_type === "monthly_subscription" && (
                              <span>
                                / <Trans>month</Trans>
                              </span>
                            )}
                            {price_type === "weekly_subscription" && (
                              <span>
                                / <Trans>week</Trans>
                              </span>
                            )}
                          </>
                        )}
                      </span>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </button>
  );
};

export default ButtonDesktop1;
