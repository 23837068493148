import React from "react";
import { connectPagination } from "react-instantsearch-dom";

export const Pagination = ({
  currentRefinement,
  nbPages,
  refine,
  createURL,
  showFirst,
  showPrevious,
  showNext,
  showLast,
  maxPages,
  className,
  loading,
}) => {
  if (nbPages < 2) {
    return null;
  }

  let startPage, endPage;

  if (nbPages < maxPages) {
    startPage = 1;
    endPage = nbPages;
  } else {
    // total pages more than max so calculate start and end pages
    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;

    if (currentRefinement <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentRefinement + maxPagesAfterCurrentPage >= nbPages) {
      // current page near the end
      startPage = nbPages - maxPages + 1;
      endPage = nbPages;
    } else {
      // current page somewhere in the middle
      startPage = currentRefinement - maxPagesBeforeCurrentPage;
      endPage = currentRefinement + maxPagesAfterCurrentPage;
    }
  }

  // create an array of pages to ng-repeat in the pager control
  let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i
  );

  const handlePageChange = (page) => (e) => {
    e.preventDefault();
    // jump('#jumpAnchor')
    refine(page);
  };

  return (
    <ul className={`ais-Pagination-list ${className}`}>
      <FirstButton
        {...{
          showFirst,
          currentRefinement,
          createURL,
          handlePageChange,
          loading,
        }}
      />
      <PrevButton
        {...{
          showPrevious,
          currentRefinement,
          createURL,
          handlePageChange,
          loading,
        }}
      />
      {pages.map((page, index) => (
        <li
          key={index}
          {...(currentRefinement === page
            ? {
                className: "ais-Pagination-link ais-Pagination-link--selected",
              }
            : { className: "ais-Pagination-link" })}
        >
          <button
            href={createURL(page)}
            onClick={handlePageChange(page)}
            disabled={currentRefinement === page || loading}
            // className="block"
          >
            {page}
          </button>
        </li>
      ))}
      <NextButton
        {...{
          showNext,
          currentRefinement,
          nbPages,
          createURL,
          handlePageChange,
          loading,
        }}
      />
    </ul>
  );
};

const FirstButton = ({
  showFirst,
  currentRefinement,
  createURL,
  handlePageChange,
  loading,
}) => {
  return (
    <>
      {showFirst && (
        <>
          {currentRefinement > 1 ? (
            <li className="ais-Pagination-link">
              <button
                href={createURL(1)}
                onClick={handlePageChange(1)}
                disabled={loading}
              >
                <span className="icon-angle-double-left" />
              </button>
            </li>
          ) : (
            <li className="ais-Pagination-link">
              <span className="icon-angle-double-left" />
            </li>
          )}
        </>
      )}
    </>
  );
};

const PrevButton = ({
  showPrevious,
  currentRefinement,
  createURL,
  handlePageChange,
  loading,
}) => {
  return (
    <>
      {showPrevious && (
        <>
          {currentRefinement > 1 ? (
            <li className="ais-Pagination-link">
              <button
                href={createURL(currentRefinement - 1)}
                onClick={handlePageChange(currentRefinement - 1)}
                disabled={loading}
              >
                <span className="icon-angle-left" />
              </button>
            </li>
          ) : (
            <li className="ais-Pagination-link">
              <span className="icon-angle-left" />
            </li>
          )}
        </>
      )}
    </>
  );
};

const NextButton = ({
  showNext,
  currentRefinement,
  nbPages,
  createURL,
  handlePageChange,
  loading,
}) => {
  return (
    <>
      {showNext && (
        <>
          {currentRefinement < nbPages ? (
            <li className="ais-Pagination-link">
              <button
                href={createURL(currentRefinement + 1)}
                onClick={handlePageChange(currentRefinement + 1)}
                disabled={loading}
              >
                <span className="icon-angle-right" />
              </button>
            </li>
          ) : (
            <li className="ais-Pagination-link">
              <span className="icon-angle-right" />
            </li>
          )}
        </>
      )}
    </>
  );
};

Pagination.defaultProps = {
  maxPages: 5,
  showPrevious: true,
  showFirst: true,
  showNext: true,
  loading: false,
};

export default connectPagination(Pagination);
