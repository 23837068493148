import React from "react";
import PropTypes from "prop-types";
import Item from "./item";

const ListDrop = (props) => {
  const data = props.data;

  return (
    <>
      {data.map((data, key) => {
        return (
          <div key={`ListDrop_${key}`}>
            <Item {...data} />
          </div>
        );
      })}
    </>
  );
};

ListDrop.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default ListDrop;
