import React, { useState, useEffect, useCallback, useContext } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { Trans, Plural } from "@lingui/macro";
import { graphql } from "gatsby";
import Sticky from "sticky-js";
import Layout from "~/components/layout";
import SEO from "~/components/seo";
import Rating from "~/components/rating";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isCourseInCart, splitSentence, toSlug } from "~/utils/app-helpers";
import * as actionCreators from "~/redux/actions/cart_actions";
import ListItems from "~/components/listItems";
import LpReviews from "~/components/lpReviews";
import SocialIcon from "~/components/socialIcon";
import Link from "~/components/link";
import MoreCourses from "~/components/moreCourses";
import ListDrop from "~/components/listDrop";
import PrevVideo from "~/components/prevVideo";
import Sanitize from "~/components/sanitize";
import Loader from "~/components/loader";
import Period from "~/components/transHelpers/period";
import navigate from "~/components/navigate";
import ButtonDesktop1 from "~/components/landingPage/buttonDesktop1";
import ButtonDesktop2 from "~/components/landingPage/buttonDesktop2";
import ButtonAddCourse from "~/components/landingPage/buttonAddCourse";
import usePrices from "~/hooks/usePrices";
import PricesContext from "~/providers/pricesProvider";
import { ModalContext } from "~/providers/modalProvider";
import { isClient, wpURL } from "~/utils/app-helpers";
import ButtonAddPro from "~/components/landingPage/buttonAddPro";

const CoursePage = ({ location, pageContext, data, auth, cart, addToCartAction }) => {
  const course = data.vtutorStructuredContent;
  const otherCourses = data.otherCourses.edges;
  const relatedCourses = data.relatedCourses.edges;

  const [isShowMore, setShowMore] = useState(false);

  const idsOtherCourses = otherCourses.map((course) => course.node.ID);
  const idsRelatedCourses = relatedCourses.map((course) => course.node.ID);

  const joinedIds = course.ID + "," + idsOtherCourses.join(",") + "," + idsRelatedCourses.join(",");

  const [prices, setPrices] = useState(null);
  const [price, setPrice] = useState(null);
  const [price_type, setPriceType] = useState("");
  const [details, setDetails] = useState("");
  const [symbol, setSymbol] = useState("");
  const [courseActive, setCourseActiveStatus] = useState(null);
  const [allCoursesActive, setAllCoursesActiveStatus] = useState(false);

  const { data: myPrices } = usePrices(joinedIds);

  const [, manageModal] = useContext(ModalContext);

  const pathName = location.pathname.endsWith("/") ? location.pathname.slice(0, -1) : location.pathname;
  const currentUrl = encodeURIComponent(`https://vtutor.com${location.pathname}`);
  const discussionBoard = `${process.env.GATSBY_WP_URL}${pathName}${pageContext.locale === "es" ? "/preguntas/" : "/questions/"}`;

  useEffect(() => {
    if (auth.coursesCounter > 0 && Object.keys(myPrices).length > 0) {
      const isActive = auth.courses.indexOf(course.ID);

      const isAllCoursesActive = auth.courses.indexOf(Number(process.env.GATSBY_ALL_COURSES_ID));

      if (isActive === -1) {
        setCourseActiveStatus(false);
      } else {
        setCourseActiveStatus(true);
      }

      if (isActive === -1 && isAllCoursesActive >= 0 && myPrices[course.ID].type === "one_time_payment" && course.promo_acceptable !== "no") {
        setAllCoursesActiveStatus(true);
      } else {
        setAllCoursesActiveStatus(false);
      }
    } else {
      setCourseActiveStatus(false);
    }

    if (Object.keys(myPrices).length > 0) {
      setPrices(myPrices);
      setPrice(myPrices[course.ID]);
      setPriceType(myPrices[course.ID].type);
      setDetails(myPrices[course.ID].sub_details);
      setSymbol(myPrices.currency.symbol);
    }

    return () => {
      return false;
    };
  }, [auth.coursesCounter, auth.courses, myPrices, course.ID]);

  useEffect(() => {
    const stickyBottom = () => {
      if (isClient) {
        const element = document.getElementById("checkThis");
        const stickyElement = document.getElementById("stickyBottom");

        let rect = element.getBoundingClientRect();

        if (rect.y <= 48) {
          stickyElement.style.display = "flex";
        } else {
          stickyElement.style.display = "none";
        }
      }
    };

    if (isClient) {
      if (window.screen.width >= 1024) new Sticky("[data-sticky]");

      if (window.screen.width < 1024)
        //da peos con safari
        window.addEventListener("scroll", stickyBottom);
    }

    return () => {
      if (isClient) {
        if (window.screen.width < 1024)
          //da peos con safari
          window.removeEventListener("scroll", stickyBottom);
      }
      return false;
    };
  }, []);

  const show_more_handle = useCallback(
    (e) => {
      e.preventDefault();

      setShowMore(!isShowMore);
    },
    [isShowMore]
  );

  const exists = isCourseInCart(course, cart.products);
  // console.log(course);
  const link = `/course/${course.permalink.category}/${course.permalink.slug}/`;

  const cartButtonAction = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!exists)
      addToCartAction({
        ID: course.ID,
        image: course.image,
        title: course.title,
        link,
      });
  };

  const cartButton = () => {
    if (price_type !== "one_time_payment") return null;
    if (price && Number(price.price) === 0) return null;

    let btnClasses = classNames("btn btn-cart", {
      "btn-primary-reverse": !exists,
      "btn-primary": exists,
    });

    return (
      <button className={btnClasses} onClick={cartButtonAction}>
        <span className="icon-shopping-cart" />
      </button>
    );
  };

  let description = splitSentence(course.description, 100);

  const isBestseller = () => {
    return !course.free && course.reviews.count > 20;
  };

  const [reviewsFilter, setReviewsFilter] = useState(0);

  const handleGoToCheckout = (e) => {
    e.preventDefault();

    if (auth.email === null && course.free) {
      if (isClient && window.screen.width < 450) {
        navigate("/login", pageContext.locale);
        return false;
      } else {
        manageModal({ login: true });
      }
    } else {
      if (isClient) {
        let courseData = {
          ID: course.ID,
          image: course.image,
          title: course.title,
          link,
        };
        let cartData = { products: [courseData], count: 0 };

        sessionStorage.setItem("vtCart", JSON.stringify(cartData));
      }

      navigate("/checkout", pageContext.locale);
      return false;
    }
  };

  const iOS = isClient && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const seeCourse = `${wpURL(pageContext.locale)}/learner/my-purchased-courses/?section=course&obj=${course.ID}&lecture=1&content_type=video`;

  const metas = [
    {
      property: "product:brand",
      content: "vTutor",
    },
    {
      property: "product:availability",
      content: "in stock",
    },
    {
      property: "product:condition",
      content: "new",
    },
    {
      property: "product:price:amount",
      content: "9.99",
    },
    {
      property: "product:price:currency",
      content: "USD",
    },
    {
      property: "product:retailer_item_id",
      content: course.ID,
    },
    {
      property: "product:item_group_id",
      content: course.permalink.category,
    },
    {
      property: "og:url",
      content: `https://vtutor.com${pageContext.paths[pageContext.locale]}`,
    },
    {
      property: "og:updated_time",
      content: new Date(course.last_update).getTime(),
    },
  ];

  return (
    <Layout footerclassName="pb-10 lg:pb-0" paths={pageContext.paths}>
      <SEO
        title={course.title}
        description={course.subtitle}
        lang={pageContext.locale}
        location={pageContext.paths}
        meta={metas}
        course_lang={course.language}
        image={course.image}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
              {
                "@context":"https://schema.org",
                "@type":"Product",
                "productID":"${course.ID}",
                "name":"${course.title}",
                "description":"${course.subtitle}",
                "url":"https://vtutor.com${pageContext.paths[pageContext.locale]}",
                "image": "${course.image}",
                "brand":"vTutor",
                "offers":[
                  {
                    "@type":"Offer",
                    "price":"9.99",
                    "priceCurrency":"USD",
                    "itemCondition":"https://schema.org/NewCondition",
                    "availability":"https://schema.org/InStock"
                  }
                ]
              }
              `}
        </script>
      </Helmet>
      <PricesContext.Provider value={prices}>
        <link itemProp="thumbnailUrl" href={course.image} />
        <span itemProp="thumbnail" itemScope itemType="http://schema.org/ImageObject">
          <link itemProp="url" href={course.image} />
        </span>

        {/* barra fija de precios en mobile */}
        <div className={`course_buy_nav lg:hidden ${iOS && "mb-3"}`} id="stickyBottom">
          {courseActive === false && allCoursesActive === false ? (
            <div className="sm:mx-2 sm:my-1 sm:rounded-brand bg-brand-primary w-full flex p-1 shadow">
              <div className="flex items-center justify-center flex-grow flex-col leading-none sm:flex-row text-lg text-white font-medium">
                {course.free ? (
                  <Trans>Free</Trans>
                ) : (
                  <>
                    {price === null ? (
                      <Loader />
                    ) : (
                      <>
                        {/* <Trans>Buy now</Trans> */}
                        {details !== "" && details.period_amount === "-1" ? (
                          <>
                            {details.period_price === "free" ? (
                              <span className="">
                                <strong>
                                  <Trans>Join free</Trans>
                                </strong>
                              </span>
                            ) : (
                              <span className="whitespace-nowrap text-sm">
                                {details.period_price} {symbol} / {details.period === "week" ? <Trans>w</Trans> : "m"}
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            {details !== "" && details.period_amount > 0 ? (
                              <>
                                {details.period_price === "free" ? (
                                  <span className="lowercase whitespace-nowrap">
                                    {details.period_amount} <Period amount={details.period_amount} period={details.period} /> <Trans>Free</Trans>
                                  </span>
                                ) : (
                                  <span className="whitespace-nowrap text-sm">
                                    {details.period_price} {symbol} / {details.period === "week" ? <Trans>w</Trans> : "m"}
                                  </span>
                                )}
                              </>
                            ) : (
                              <span className="whitespace-nowrap text-xl">
                                {price !== null && (
                                  <>
                                    {price.price > 0 ? (
                                      <>
                                        {price.price} {symbol}
                                      </>
                                    ) : (
                                      <>
                                        <Trans>Its free!</Trans>
                                      </>
                                    )}
                                  </>
                                )}
                                {price_type !== "one_time_payment" && (
                                  <>
                                    {price_type === "monthly_subscription" && <span> / m</span>}
                                    {price_type === "weekly_subscription" && (
                                      <span>
                                        {" "}
                                        / <Trans>w</Trans>
                                      </span>
                                    )}
                                  </>
                                )}
                              </span>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="flex space-x-2">
                {price && price.price > 0 && price_type === "one_time_payment" && (
                  <button
                    className={`btn btn-primary px-3 rounded-brand text-center md:px-8 ${exists && "text-brand-primary-light"}`}
                    onClick={cartButtonAction}
                    disabled={price === null}
                  >
                    <span className="icon-shopping-cart" />
                  </button>
                )}
                <button
                  className="btn btn-primary-reverse px-3 sm:px-5 font-bold text-lg rounded-brand w-32 lowercase capitalize-letter"
                  onClick={handleGoToCheckout}
                  onTouchStart={function () {}}
                >
                  {course.free || price_type !== "one_time_payment" || (price && price.price == 0) ? <Trans>Join now</Trans> : <Trans>BUY NOW</Trans>}
                </button>
              </div>
            </div>
          ) : (
            <div className="sm:mx-2 sm:my-1 sm:rounded-brand bg-brand-primary w-full flex p-1 shadow md:hidden">
              {allCoursesActive ? (
                <ButtonAddCourse course_id={course.ID} />
              ) : (
                <a href={seeCourse} className="btn btn-primary w-full leading-tight flex items-center justify-center space-x-2 h-full">
                  {courseActive === null ? (
                    <Loader />
                  ) : (
                    <>
                      <span className="uppercase">
                        <Trans>View Course</Trans>
                      </span>
                      <span className="icon icon-play-circle-s" />
                    </>
                  )}
                </a>
              )}
            </div>
          )}
        </div>
        <div className="md:flex md:flex-row-reverse md:justify-between md:items-center lg:max-w-6xl lg:mx-auto lg:h-86">
          {/*  video sample   */}
          <PrevVideo url={course.video_link} thumbnail={course.image} className="sm:h-58 lg:h-72 xl:h-74" />
          <div className="md:w-11/20 md:mb-6 lg:flex lg:flex-col">
            {/* reviews AVG  */}
            {course.reviews.count > 10 && (
              <div className="flex items-center justify-between m-4 md:m-0 md:mt-6 md:ml-4">
                <div className="text-sm flex items-center text-brand-link-gray">
                  <a href="#reviews">
                    <Rating reviews_avg={course.reviews_avg} />
                    <span className="ml-1 text-xs sm:text-sm md:ml-3 hidden md:inline-block">
                      <Plural value={course.reviews.count} zero="There're no reviews" one="# review" other="# reviews" />
                    </span>
                  </a>
                  {isBestseller() && (
                    <span className="text-xs bg-bestseller p-1 px-2 font-medium leading-snug text-gray-800 tags ml-5 md:ml-6">
                      <Trans>Bestseller</Trans>
                    </span>
                  )}
                </div>
              </div>
            )}
            <div className="md:flex md:flex-col-reverse md:relative lg:mb-3">
              <div className="md:absolute md:right-0 md:mt-4 lg:-mt-1">
                {/* { Aca va la barra de detalles} */}
                <div className="flex items-center justify-between text-xs sm:text-sm mx-4 mb-2 md:space-x-4">
                  <>
                    {course.students > 10 ? (
                      <span>
                        <span className="icon-user mr-1" />
                        <span className="space-x-2">
                          <span>{course.students}</span>
                          <span className="md:hidden">
                            <Trans>students</Trans>
                          </span>
                        </span>
                      </span>
                    ) : (
                      <span>
                        <span className="icon-book-open mr-1" />
                        {course.total_lectures} <Trans>lectures</Trans>
                      </span>
                    )}
                  </>
                  <span>
                    {course.discutions > 10 ? (
                      <>
                        <span className="icon-comment mr-1" />
                        {course.discutions}
                      </>
                    ) : (
                      <>
                        <span className="icon-file-certificate mr-1" />
                        <Trans>Certificate included</Trans>
                      </>
                    )}
                  </span>
                  <span>
                    <span className="icon-clock mr-1" />
                    {course.duration}
                  </span>
                </div>
                {/* Last update */}
                <div className="flex flex-row items-center justify-between mt-3">
                  <div className="mx-4 space-x-2 text-sm md:flex md:flex-col md:space-x-0">
                    <span className="text-gray-400">
                      <Trans>Language</Trans>:
                    </span>
                    <span className="Capitalize">{course.language === "en" ? "English" : "Español"}</span>
                  </div>
                  <div className="mx-4 space-x-2 text-sm md:flex md:flex-col md:space-x-0">
                    <span className="text-gray-400 whitespace-nowrap">
                      <Trans>Last updated:</Trans>
                    </span>
                    <span>{new Date(course.last_update).toLocaleDateString(pageContext.locale)}</span>
                  </div>
                </div>
              </div>
              {/* Titulo  */}
              <div className="mx-4 mt-2 lg:mt-4">
                <Sanitize html={course.title} className="leading-tight text-xl sm:text-2xl md:text-3xl font-bold" tag="h1" />
                <Sanitize html={course.subtitle} className="text-sm  sm:text-base text-gray-800 mt-2 lg:mt-4 lg:mb-2 xl:mb-4" tag="div" />
              </div>
              {/* Autor  */}
              <div className="mx-4 mt-4 hidden md:block md:order-first lg:mt-6">
                <Link
                  to={`/public-profile/${course.tutor.id}/${toSlug(course.tutor.name)}/`}
                  className="inline-flex leading-none items-center md:flex-col  lg:flex-row lg:items-center"
                >
                  <img
                    src={getAvatarUrl(course.tutor.avatar)}
                    alt=""
                    className="rounded-full border border-gray-400 mr-4 object-cover"
                    style={{ width: 48, height: 48 }}
                  />
                  <Sanitize html={course.tutor.name} className="flex text-sm text-gray-700 md:mt-3 lg:mt-0 text-center lg:text-left" />
                </Link>
              </div>
              <Link
                to={`/public-profile/${course.tutor.id}/${toSlug(course.tutor.name)}/`}
                className="leading-none items-start ml-4 text-sm md:hidden mt-2 mb-6 inline-flex"
              >
                <Trans>By</Trans>
                <Sanitize html={course.tutor.name} className="flex text-gray-700 ml-1" />
              </Link>
              {/* Bloque de descuento y precios de mobile 1 */}
              {courseActive === false && allCoursesActive === false ? (
                <div className="px-4 pb-4 text-center text-sm md:hidden">
                  {price !== null && price.price_old !== "" && price.price !== price.price_old && (
                    <div className="whitespace-nowrap leading-tight text-sm text-left mb-2 flex flex-col">
                      <div>
                        <span className="line-through text-gray-600 font-medium">{price.price_old}</span>
                        <span className="bg-green-200 border border-green-200 p-1 px-2 text-green-700 rounded-brand text-xs ml-2 font-medium">
                          {Math.floor(100 - (Number(price.price) * 100) / Number(price.price_old))}% OFF
                        </span>
                      </div>
                    </div>
                  )}
                  {price !== null && price.sub_details !== "" && (
                    <>
                      {price.sub_details.period_amount !== "" && (
                        <div className="mb-2 text-left">
                          <span className="line-through text-gray-600 font-medium">
                            {price.price}
                            {price_type !== "one_time_payment" && (
                              <>
                                {price_type === "monthly_subscription" && (
                                  <span>
                                    {" "}
                                    / <Trans>month</Trans>
                                  </span>
                                )}
                                {price_type === "weekly_subscription" && (
                                  <span>
                                    {" "}
                                    / <Trans>week</Trans>
                                  </span>
                                )}
                              </>
                            )}
                          </span>
                          <span className="bg-green-200 border border-green-200 p-1 px-2 text-green-700 rounded-brand text-xs ml-2 font-medium uppercase">
                            <Trans>Sale</Trans>
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {(price !== null && price.price_old !== "" && price.price !== price.price_old) ||
                    (price !== null && price.sub_details !== "" && (
                      <div className="text-red-600 font-medium text-left">
                        ⏰ <Trans>This offer expires soon!</Trans>
                      </div>
                    ))}
                  {/* call to action mobile 1  */}
                  <ButtonAddPro course_type={price_type} promo_acceptable={course.promo_acceptable} auth={auth} />
                  <button
                    className="btn btn-primary-reverse whitespace-nowrap px-1 w-full mb-2 text-xl mt-2"
                    disabled={price === null}
                    onClick={handleGoToCheckout}
                    onTouchStart={function () {}}
                  >
                    {course.free ? (
                      <Trans>Join free</Trans>
                    ) : (
                      <>
                        {price === null ? (
                          <Loader />
                        ) : (
                          <>
                            {/* <Trans>Buy now</Trans> */}
                            {details !== "" && details.period_amount === "-1" ? (
                              <>
                                {details.period_price === "free" ? (
                                  <span className="">
                                    <strong>
                                      <Trans>Join free</Trans>
                                    </strong>
                                  </span>
                                ) : (
                                  <span className="leading-tight">
                                    <p>
                                      <Trans>Join now</Trans>
                                    </p>
                                    <p className="text-xs">
                                      {details.period_price} {symbol} / {details.period === "week" ? <Trans>week</Trans> : <Trans>month</Trans>}
                                    </p>
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {details !== "" && details.period_amount > 0 ? (
                                  <>
                                    {details.period_price === "free" ? (
                                      <span className="">
                                        <Trans>Get</Trans> {details.period_amount} <Period amount={details.period_amount} period={details.period} />{" "}
                                        <Trans>free</Trans>
                                      </span>
                                    ) : (
                                      <span className="leading-tight">
                                        <p className=" capitalize-letter">
                                          <Trans>Get</Trans> {details.period_amount} <Period amount={details.period_amount} period={details.period} />
                                        </p>
                                        <p className="text-xs">
                                          <Trans>only</Trans> {details.period_price} {symbol}
                                        </p>
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <span className="leading-tight">
                                    {price_type === "one_time_payment" && price.price > 0 ? (
                                      <>
                                        <Trans>Buy now</Trans> {price.price} {symbol}
                                      </>
                                    ) : (
                                      <>
                                        <p>
                                          <Trans>Join now</Trans>
                                        </p>
                                        <p>
                                          {price && price.price > 0 ? (
                                            <>
                                              {price.price} {symbol}
                                            </>
                                          ) : (
                                            <>
                                              <Trans>Its free!</Trans>
                                            </>
                                          )}
                                          {price_type === "monthly_subscription" && (
                                            <span>
                                              {" "}
                                              / <Trans>month</Trans>
                                            </span>
                                          )}
                                          {price_type === "weekly_subscription" && (
                                            <span>
                                              {" "}
                                              / <Trans>week</Trans>
                                            </span>
                                          )}
                                        </p>
                                      </>
                                    )}
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </button>
                  {price && price.price > 0 && price_type === "one_time_payment" && (
                    <button
                      className={`btn btn-primary-reverse px-3 rounded-brand text-center md:px-8 w-full mb-2 text-xl ${
                        exists && "text-brand-primary-light"
                      }`}
                      onClick={cartButtonAction}
                      disabled={price === null}
                    >
                      <Trans>Add to cart</Trans> <span className="icon-shopping-cart" />
                    </button>
                  )}
                  <Trans>30-Days Money-Back Guarantee</Trans>

                  {price !== null && details !== "" && details.period_amount !== "-1" && (
                    <div className="text-xs mt-1">
                      <Trans>After trial</Trans>{" "}
                      {price.sub_details.period_amount !== "" && (
                        <span className="">
                          {price.price} {symbol}
                          {price_type !== "one_time_payment" && (
                            <>
                              {price_type === "monthly_subscription" && (
                                <span>
                                  {" "}
                                  / <Trans>month</Trans>
                                </span>
                              )}
                              {price_type === "weekly_subscription" && (
                                <span>
                                  {" "}
                                  / <Trans>week</Trans>
                                </span>
                              )}
                            </>
                          )}
                        </span>
                      )}
                      <div>
                        <Trans>Cancel any time</Trans>.
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="px-4 pb-4 md:hidden">
                  {allCoursesActive ? (
                    <ButtonAddCourse course_id={course.ID} />
                  ) : (
                    <a href={seeCourse} className="btn btn-primary w-full leading-tight flex items-center justify-center space-x-2 h-full">
                      {courseActive === null ? (
                        <Loader />
                      ) : (
                        <>
                          <span className="uppercase">
                            <Trans>View Course</Trans>
                          </span>
                          <span className="icon icon-play-circle-s" />
                        </>
                      )}
                    </a>
                  )}
                </div>
              )}
            </div>
            {/* Bloque de descuento y precios 1 */}
            <div className="hidden  lg:flex">
              <ButtonAddPro course_type={price_type} promo_acceptable={course.promo_acceptable} auth={auth} />
            </div>
            <div className="hidden lg:flex lg:items-center lg:mt-auto lg:space-x-2 lg:justify-end border-t border-gray-200 pt-2">
              {courseActive === false && allCoursesActive === false ? (
                <>
                  {price !== null && price.price_old !== "" && price.price !== price.price_old && (
                    <div className="whitespace-nowrap leading-tight text-sm text-center">
                      <span className="line-through text-gray-600 font-medium">
                        {price.price_old} {symbol}
                      </span>
                      <span className="bg-green-200 border border-green-200 p-1 px-2 text-green-700 rounded-brand text-xs ml-2 font-medium">
                        {Math.floor(100 - (Number(price.price) * 100) / Number(price.price_old))}% OFF
                      </span>
                    </div>
                  )}
                  {price !== null && price.sub_details !== "" && (
                    <>
                      {price.sub_details.period_amount !== "" && (
                        <>
                          <span className="bg-green-200 border border-green-200 p-1 px-2 text-green-700 rounded-brand text-xs ml-2 font-medium uppercase">
                            <Trans>Sale</Trans>
                          </span>
                          <span className="line-through text-gray-600 mr-2 font-medium">
                            {price.price}
                            {price_type !== "one_time_payment" && (
                              <>
                                {price_type === "monthly_subscription" && (
                                  <span>
                                    {" "}
                                    / <Trans>month</Trans>
                                  </span>
                                )}
                                {price_type === "weekly_subscription" && (
                                  <span>
                                    {" "}
                                    / <Trans>week</Trans>
                                  </span>
                                )}
                              </>
                            )}
                          </span>
                        </>
                      )}
                    </>
                  )}
                  {/* call to action desktop 1  */}
                  <ButtonDesktop1
                    price={price}
                    price_type={price_type}
                    symbol={symbol}
                    handleGoToCheckout={handleGoToCheckout}
                    course={course}
                    details={details}
                  />
                  {price && price.price > 0 && cartButton()}
                </>
              ) : (
                <>
                  {allCoursesActive ? (
                    <ButtonAddCourse course_id={course.ID} />
                  ) : (
                    <a href={seeCourse} className="btn btn-primary w-full leading-tight flex items-center justify-center space-x-2 h-10">
                      {courseActive === null ? (
                        <Loader />
                      ) : (
                        <>
                          <span className="uppercase">
                            <Trans>View Course</Trans>
                          </span>
                          <span className="icon icon-play-circle-s" />
                        </>
                      )}
                    </a>
                  )}
                </>
              )}
            </div>
            {/* {price !== null && details !== "" && details.period_amount !== "-1" && (
              <div className="text-xs mt-1 text-center">
                <Trans>30-Days Money-Back Guarantee</Trans> - <Trans>After trial</Trans>{" "}
                {price.sub_details.period_amount !== "" && (
                  <span className="">
                    {price.price}
                    {price_type !== "one_time_payment" && (
                      <>
                        {price_type === "monthly_subscription" && (
                          <span>
                            {" "}
                            / <Trans>month</Trans>
                          </span>
                        )}
                        {price_type === "weekly_subscription" && (
                          <span>
                            {" "}
                            / <Trans>week</Trans>
                          </span>
                        )}
                      </>
                    )}
                  </span>
                )}
                . <Trans>Cancel any time</Trans>.
              </div>
            )} */}
          </div>
        </div>
        <div
          style={{
            background: "linear-gradient(180deg, #F1F4F5 0%, rgba(241, 244, 245, 0) 100%)",
          }}
        >
          <div className="lg:flex lg:max-w-6xl lg:mx-auto" data-sticky-container id="checkThis">
            <div className="p-4 lg:w-8/12">
              {/* Que vas a aprender  */}
              <div className="border-b border-gray-200 mb-5 pb-2">
                <h3 className="mt-3">
                  <Trans>What You’ll Learn</Trans>
                </h3>
                <ListItems data={course.what_you_will_learn} slice={5} />
              </div>
              {/* Contenido incluido  */}
              <div className=" border-b border-gray-200 mb-5 pb-2">
                <h3 className="mb-3">
                  <Trans>Included content</Trans>
                </h3>
                <ListDrop data={course.sections} />
              </div>
              {/*  Descripcion */}
              <div className=" border-b border-gray-200 mb-5 pb-2">
                <h3>
                  <Trans>Description</Trans>
                </h3>
                <div className="bg-white border-gray-200 border p-3 relative my-3 rounded-brand lg:p-4">
                  <div className="relative course-description">
                    {description.splited && !isShowMore && (
                      <div
                        className="absolute bottom-0 left-0 mb-8 w-full h-32"
                        style={{
                          backgroundImage:
                            "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0.27), rgba(255, 255, 255, 0.36), rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.64), rgba(255, 255, 255, 0.73), rgba(255, 255, 255, 0.82), rgba(255, 255, 255, 0.91), rgb(255, 255, 255))",
                        }}
                      />
                    )}
                    {description.splited ? (
                      <>
                        {!isShowMore ? (
                          <Sanitize html={description.text} className="descShort" tag="div" />
                        ) : (
                          <Sanitize html={course.description} className="descFull" tag="div" />
                        )}
                      </>
                    ) : (
                      <Sanitize html={course.description} className="descFull" tag="div" />
                    )}
                    {description.splited && (
                      <div className="text-right" id="reviews">
                        <button onClick={show_more_handle} className={`inline-flex items-center space-x-2 font-medium`}>
                          <Trans>See</Trans> {!isShowMore ? <Trans>more</Trans> : <Trans>less</Trans>}
                          <span className={`icon-angle-down-l rotate-icon text-brand-primary text-xl ml-2 ${isShowMore && "rotate-icon--opened"}`} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Rating de cursos  */}
              <div className="border-b border-gray-200 mb-5 pb-2">
                <h3>
                  <Trans>User reviews</Trans>
                </h3>
                <div className=" bg-white border-gray-200 border p-3 relative my-3 rounded-brand">
                  <Trans>Course rating</Trans> : <span className="text-brand-primary font-medium">{course.reviews_avg}</span>
                  <div className="text-sm flex items-center text-brand-link-gray mt-3">
                    <Plural value={course.reviews.count} zero="There're no reviews" one="# review" other="# reviews" />
                    <Rating reviews_avg={course.reviews_avg} className="ml-2" />
                  </div>
                  <table className="m-auto mt-4 w-full">
                    <tbody>
                      <tr>
                        <td className="flex items-center justify-between text-brand-primary w-10">
                          5 <span className="icon-star " />
                        </td>
                        <td className="w-full px-6">
                          <div className="w-full bg-gray-400 relative leading-none" style={{ height: 2 }}>
                            <div
                              className="bg-brand-primary absolute top-0 left-0 rounded-brand"
                              style={{
                                height: 8,
                                marginTop: -3,
                                width: `${(course.reviews.details._5 * 100) / course.reviews.count}%`,
                              }}
                            />
                          </div>
                        </td>
                        <td>{course.reviews.details._5}</td>
                        <td>
                          <button
                            className={`w-10 text-gray-600 ${reviewsFilter === 5 && "text-brand-primary"}`}
                            onClick={() => {
                              if (reviewsFilter === 5) setReviewsFilter(0);
                              else setReviewsFilter(5);
                            }}
                          >
                            <span className="icon-eye" />
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="flex items-center  justify-between text-brand-primary">
                          4 <span className="icon-star " />
                        </td>
                        <td className="w-full px-6">
                          <div className="w-full bg-gray-400 relative leading-none" style={{ height: 2 }}>
                            <div
                              className="bg-brand-primary absolute top-0 left-0 rounded-brand"
                              style={{
                                height: 8,
                                marginTop: -3,
                                width: `${(course.reviews.details._4 * 100) / course.reviews.count}%`,
                              }}
                            />
                          </div>
                        </td>
                        <td>{course.reviews.details._4}</td>
                        <td>
                          <button
                            className={`w-10 text-gray-600 ${reviewsFilter === 4 && "text-brand-primary"}`}
                            onClick={() => {
                              if (reviewsFilter === 4) setReviewsFilter(0);
                              else setReviewsFilter(4);
                            }}
                          >
                            <span className="icon-eye" />
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="flex items-center  justify-between text-brand-primary">
                          3 <span className="icon-star " />
                        </td>
                        <td className="w-full px-6">
                          <div className="w-full bg-gray-400 relative leading-none" style={{ height: 2 }}>
                            <div
                              className="bg-brand-primary absolute top-0 left-0 rounded-brand"
                              style={{
                                height: 8,
                                marginTop: -3,
                                width: `${(course.reviews.details._3 * 100) / course.reviews.count}%`,
                              }}
                            />
                          </div>
                        </td>
                        <td>{course.reviews.details._3}</td>
                        <td>
                          <button
                            className={`w-10 text-gray-600 ${reviewsFilter === 3 && "text-brand-primary"}`}
                            onClick={() => {
                              if (reviewsFilter === 3) setReviewsFilter(0);
                              else setReviewsFilter(3);
                            }}
                          >
                            <span className="icon-eye" />
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="flex items-center  justify-between text-brand-primary">
                          2 <span className="icon-star " />
                        </td>
                        <td className="w-full px-6">
                          <div className="w-full bg-gray-400 relative leading-none" style={{ height: 2 }}>
                            <div
                              className="bg-brand-primary absolute top-0 left-0 rounded-brand"
                              style={{
                                height: 8,
                                marginTop: -3,
                                width: `${(course.reviews.details._2 * 100) / course.reviews.count}%`,
                              }}
                            />
                          </div>
                        </td>
                        <td>{course.reviews.details._2}</td>
                        <td>
                          <button
                            className={`w-10 text-gray-600 ${reviewsFilter === 2 && "text-brand-primary"}`}
                            onClick={() => {
                              if (reviewsFilter === 2) setReviewsFilter(0);
                              else setReviewsFilter(2);
                            }}
                          >
                            <span className="icon-eye" />
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="flex items-center justify-between text-brand-primary">
                          1 <span className="icon-star " />
                        </td>
                        <td className="w-full px-6">
                          <div className="w-full bg-gray-400 relative leading-none" style={{ height: 2 }}>
                            <div
                              className="bg-brand-primary absolute top-0 left-0 rounded-brand"
                              style={{
                                height: 8,
                                marginTop: -3,
                                width: `${(course.reviews.details._1 * 100) / course.reviews.count}%`,
                              }}
                            />
                          </div>
                        </td>
                        <td>{course.reviews.details._1}</td>
                        <td>
                          <button
                            className={`w-10 text-gray-600 ${reviewsFilter === 1 && "text-brand-primary"}`}
                            onClick={() => {
                              if (reviewsFilter === 1) setReviewsFilter(0);
                              else setReviewsFilter(1);
                            }}
                          >
                            <span className="icon-eye" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <strong className="my-4 block">
                    <Trans>Comments</Trans>
                  </strong>
                  {course.reviews.count > 0 ? (
                    <LpReviews course_id={course.ID} lang={pageContext.locale} filter={reviewsFilter} />
                  ) : (
                    <Trans>No comments yet</Trans>
                  )}
                  <a type="primary mt-4 block" href={discussionBoard}>
                    <span className="flex items-center justify-end text-brand-primary space-x-2 font-medium">
                      <p className="ml-2">
                        <Trans>Discussion Board</Trans>
                      </p>
                      <span className="icon-angle-right text-xl" />
                    </span>
                  </a>
                </div>
              </div>
              {/* Acerca del autor  */}
              <div className="border-b border-gray-200 mb-5 pb-2">
                <h3>
                  <Trans>About the autor</Trans>
                </h3>
                <div className="bg-white border-gray-200 border p-3 relative my-3 rounded-brand">
                  <div className="flex flex-col lg:flex-row lg:justify-between">
                    <Link to={`/public-profile/${course.tutor.id}/${toSlug(course.tutor.name)}/`} className="flex items-center mt-2 leading-snug">
                      <img
                        src={getAvatarUrl(course.tutor.avatar)}
                        alt=""
                        className="rounded-full h-12 w-12 border border-gray-400 mb-2"
                        style={{ width: 64, height: 64 }}
                      />
                      <div className="pl-4">
                        <Sanitize html={course.tutor.name} className="font-medium" />
                        <p className="mt-1 text-gray-400 text-sm">{course.tutor.description}</p>
                      </div>
                    </Link>
                    <div className="text-center bordered lg:px-6">
                      <div className="flex space-x-2 justify-start mt-3 md:mt-0">
                        <div className="text-center leading-tight text-brand-primary">
                          <span className="icon-play-circle" />
                        </div>
                        <span className="text-sm text-gray-800">
                          <Plural value={course.tutor.courses} zero="There're no courses" one="# course" other="# courses" />
                        </span>
                      </div>
                      <div className="flex space-x-2 pb-2 justify-start">
                        <div className="text-center leading-tight text-brand-primary">
                          <span className="icon-user" />
                        </div>
                        <span className="text-sm text-gray-800">
                          <Plural value={course.tutor.students} zero="There're no students" one="# student" other="# students" />
                        </span>
                      </div>
                      <div className="flex space-x-2 pb-2 mt-4 justify-start">
                        <div className="text-center leading-tight text-brand-primary">
                          <span className="icon-star-l" />
                        </div>
                        <span className="text-sm text-gray-800">
                          {course.tutor.rating} <Trans>Stars Tutor Rating</Trans>
                        </span>
                      </div>
                    </div>
                  </div>
                  {otherCourses.length > 0 && (
                    <>
                      <div className="my-4 font-medium">
                        <Trans>More Courses by </Trans> <Sanitize html={course.tutor.name} />
                      </div>
                      <MoreCourses data={otherCourses} loop={false} enableButtons={true} />
                    </>
                  )}
                </div>
              </div>
              {relatedCourses.length > 0 && (
                <>
                  <div className="my-4 font-medium">
                    <Trans>More Courses in this category</Trans>
                  </div>
                  <MoreCourses data={relatedCourses} loop={false} enableButtons={true} />
                </>
              )}
            </div>
            <div className="hidden lg:w-4/12 lg:block pt-2">
              <div className="bg-white border-gray-200 border mr-4 rounded-brand" data-sticky data-margin-top="15">
                <PrevVideo url={course.video_link} thumbnail={course.image} className="xl:h-58" />
                <div className="p-3">
                  <Sanitize html={course.title} className="leading-tight my-3 border-gray-200 border-b mb-4 pb-4" tag="h3" />
                  <ul className="ml-8 text-sm space-y-3 text-gray-800 mb-3">
                    {parseInt(course.total_hours) > 0 && (
                      <li className="flex items-center space-x-2">
                        <span className="icon-clock" />
                        <span>{parseInt(course.total_hours)}</span>
                        <span>
                          <Trans>hours of on-demand video</Trans>
                        </span>
                      </li>
                    )}
                    <li className="space-x-2">
                      <span className="icon-download" />
                      <span>{course.total_resources}</span>
                      <span>
                        <Plural
                          value={course.total_resources}
                          zero="There're no resources"
                          one="educational resource"
                          other="educational resources"
                        />
                      </span>
                    </li>
                    <li>
                      {price !== null && price_type !== "one_time_payment" ? (
                        <>
                          <span className="icon-feed mr-2" />
                          <Trans>Live classes</Trans>
                        </>
                      ) : (
                        <>
                          <span className="icon-infinity mr-2" />
                          <Trans>Lifetime Access</Trans>
                        </>
                      )}
                    </li>
                    <li>
                      <span className="icon-file-certificate mr-2" />
                      <Trans>Completion Certificate</Trans>
                    </li>
                    <li>
                      <span className="icon-question-square mr-2" />
                      <Trans>Free tutor consultation</Trans>
                    </li>
                    <li>
                      <span className="icon-phone-laptop mr-2" />
                      <Trans>Compatible with all devices</Trans>
                    </li>
                  </ul>

                  {courseActive === false && allCoursesActive === false ? (
                    <>
                      {((price !== null && price.price_old !== "" && price.price !== price.price_old) ||
                        (price !== null && price.sub_details !== "")) && (
                        <div className="text-red-600 font-medium mt-3 pt-3 border-t border-gray-200 text-center">
                          ⏰ <Trans>This offer expires soon!</Trans>
                        </div>
                      )}
                      {/* call to action desktop 2  */}
                      <div className="border-t border-gray-200 pt-1 mt-2">
                        <ButtonAddPro course_type={price_type} promo_acceptable={course.promo_acceptable} auth={auth} />
                        <div className="flex items-center space-x-2 justify-between mt-2">
                          <div className="space-x-2 flex-1 flex justify-between">
                            <ButtonDesktop2
                              price={price}
                              price_type={price_type}
                              symbol={symbol}
                              handleGoToCheckout={handleGoToCheckout}
                              course={course}
                              details={details}
                            />
                            {price && price.price > 0 && cartButton()}
                          </div>
                        </div>
                        {/* {course.price_old > 0 && (
                    <div className="whitespace-nowrap text-sm text-center">
                      <span className="line-through text-gray-700">{`${format(
                        formatter("EUR").format(course.price_old)
                      )}`}</span>
                    </div>
                  )} */}
                      </div>
                      {price !== null && details !== "" && details.period_amount !== "-1" && (
                        <div className="text-xs mt-1 text-center">
                          <Trans>30-Days Money-Back Guarantee</Trans> <br /> <Trans>After trial</Trans>{" "}
                          {price.sub_details.period_amount !== "" && (
                            <span className="">
                              {price.price} {symbol}
                              {price_type !== "one_time_payment" && (
                                <>
                                  {price_type === "monthly_subscription" && (
                                    <span>
                                      {" "}
                                      / <Trans>month</Trans>
                                    </span>
                                  )}
                                  {price_type === "weekly_subscription" && (
                                    <span>
                                      {" "}
                                      / <Trans>week</Trans>
                                    </span>
                                  )}
                                </>
                              )}
                            </span>
                          )}
                          . <Trans>Cancel any time</Trans>.
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {allCoursesActive ? (
                        <ButtonAddCourse course_id={course.ID} />
                      ) : (
                        <a href={seeCourse} className="btn btn-primary w-full leading-tight flex items-center justify-center space-x-2 h-10">
                          {courseActive === null ? (
                            <Loader />
                          ) : (
                            <>
                              <span className="uppercase">
                                <Trans>View Course</Trans>
                              </span>
                              <span className="icon icon-play-circle-s" />
                            </>
                          )}
                        </a>
                      )}
                    </>
                  )}

                  <div className="flex items-center justify-center mt-3 border-t border-gray-200 space-x-3 pt-2">
                    <span>
                      <Trans>Share</Trans>
                      <span className="icon-share ml-2 text-gray-600" />
                    </span>

                    <SocialIcon
                      social="facebook"
                      url={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                      className="text-3xl text-gray-600"
                    />
                    <SocialIcon social="twitter" url={`https://twitter.com/home?status=${currentUrl}`} className="text-3xl text-gray-600" />
                    <SocialIcon
                      social="linkedin"
                      url={`https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`}
                      className="text-3xl text-gray-600"
                    />
                  </div>
                </div>
              </div>
              {/* </Sticky> */}
            </div>
          </div>
        </div>
      </PricesContext.Provider>
    </Layout>
  );
};

export const query = graphql`
  query ($ID: Int!, $tutor_courses: [Int], $category: String!, $tutor_id: Int!) {
    vtutorStructuredContent(ID: { eq: $ID }) {
      ID
      board_permalink
      description
      featured_review {
        date(formatString: "MMMM DD, YYYY")
        description
        name
        stars
        student_avatar
        verified
      }
      language
      last_update(formatString: "MMMM DD, YYYY")
      sections {
        lectures {
          content {
            description
          }
          lecture_duration
          lecture_resources_count
          title
          video_url
          resource_types
        }
        section_duration
        section_lectures_count
        section_resources_count
        title
      }
      image
      free
      promo_acceptable
      reviews {
        count
        details {
          _1
          _2
          _3
          _4
          _5
        }
      }
      reviews_avg
      students
      subtitle
      title
      total_hours
      total_resources
      total_lectures
      tutor {
        id
        avatar
        courses
        description
        name
        rating
        students
      }
      video_link
      what_you_will_learn
      duration
      discutions
      permalink {
        category
        slug
      }
    }
    otherCourses: allVtutorStructuredContent(
      sort: { fields: created_at, order: DESC }
      limit: 6
      filter: { ID: { in: $tutor_courses }, free: { eq: false } }
    ) {
      edges {
        node {
          ID
          image
          tutor {
            name
            avatar
          }
          title
          students
          reviews_avg
          reviews {
            count
          }
          free
          permalink {
            category
            slug
          }
          language
          total_hours
          total_lectures
          duration
          discutions
          created_at
        }
      }
    }
    relatedCourses: allVtutorStructuredContent(
      sort: { fields: created_at, order: DESC }
      limit: 6
      filter: { permalink: { category: { eq: $category } }, tutor: { id: { ne: $tutor_id } }, free: { eq: false } }
    ) {
      edges {
        node {
          ID
          image
          tutor {
            id
            name
            avatar
          }
          title
          students
          reviews_avg
          reviews {
            count
          }
          free
          permalink {
            category
            slug
          }
          language
          total_hours
          total_lectures
          duration
          discutions
          created_at
        }
      }
    }
  }
`;

const mapStateToProps = ({ cart, auth }) => {
  return {
    cart,
    auth,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(actionCreators, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(CoursePage);

function getAvatarUrl(url) {
  if (url.includes("data:image")) return url;
  else return `https://res.cloudinary.com/ie1/image/fetch/q_auto:best,f_auto,w_96,h_96/${url}`;
}
