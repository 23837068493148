import React, { useState, useEffect, useRef, useReducer } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Trans } from "@lingui/macro";
import Rating from "./rating";
import { Pagination } from "./deepSearch/pagination";
import Avatar from "./avatar";

import("dayjs/locale/es");

const LpReviews = ({ course_id, lang, filter }) => {
  const [isSending, setIsSending] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(1);

  const reducer = (state, action) => {
    return { ...state, ...action };
  };

  const [cache, dispatch] = useReducer(reducer, {});

  const isMounted = useRef(false);

  dayjs.locale(lang);

  useEffect(() => {
    isMounted.current = true;
    // send the actual request
    handleChangePage(1, filter);

    return () => {
      isMounted.current = false;
    };
  }, [filter]);

  const handleChangePage = (page, filter) => {
    setIsSending(true);
    setPage(page);

    let cPage = `page-${page}-${filter}`;

    if (cache != null && typeof cache[cPage] !== "undefined") {
      if (isMounted.current) {
        setReviews(cache[`page-${page}-${filter}`]);
        // once the request is sent, update state again
        setIsSending(false);
      }
    } else {
      fetchReviews(page);
    }
  };

  const fetchReviews = (page = 1) => {
    let url = `${process.env.GATSBY_API_LP_REVIEWS}/course/${course_id}/reviews`;

    url = `${url}?page=${page}&filter=${filter}`;

    fetch(url, {
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json()) // parse JSON from request
      .then((resultData) => {
        if (isMounted.current) {
          setReviews(resultData);
          dispatch({ [`page-${page}-${filter}`]: resultData });
          // once the request is sent, update state again
          setIsSending(false);
        }
      })
      .catch(function (error) {
        console.log("Request failed", error);
        // once the request is sent, update state again
        setIsSending(false);
      });
  };

  return (
    <>
      {isSending ? (
        <div className="bordered">
          {typeof reviews.total_pages !== "undefined" && reviews.total_pages > 1 ? (
            <>
              <LpReviewsCardMock />
              <LpReviewsCardMock />
              <LpReviewsCardMock />
              <LpReviewsCardMock />
            </>
          ) : (
            <LpReviewsCardMock />
          )}
        </div>
      ) : (
        <>
          {typeof reviews.rows !== "undefined" && reviews.rows.length > 0 ? (
            <div className="bordered">
              {reviews.rows.map((val, index) => {
                return <LpReviewsCard data={val} key={`lp-review-${index}`} lang={lang} />;
              })}
            </div>
          ) : (
            <>
              <Trans>No comments yet</Trans>
            </>
          )}
        </>
      )}
      {typeof reviews.total_pages !== "undefined" && reviews.total_pages > 1 && (
        <>
          <Pagination
            currentRefinement={page}
            nbPages={reviews.total_pages}
            refine={handleChangePage}
            createURL={() => {
              return "#";
            }}
            className="mt-6"
            loading={isSending}
          />
        </>
      )}
    </>
  );
};

const LpReviewsCard = ({ data, lang }) => {
  dayjs.extend(relativeTime);
  const date = dayjs().to(dayjs(data.comment_date));

  return (
    <div className="pb-4 pt-2">
      {/* { Aca va datos del autor} */}
      <div className="flex items-center mt-2 justify-between">
        <div className="flex items-center">
          <Avatar url={data.profile_picture ? data.profile_picture : ""} name={data.comment_author} />
          <span className="text-gray-800 capitalize font-medium">{sliceEmail(data.comment_author.toLowerCase())}</span>
        </div>
        <Rating reviews_avg={parseFloat(data.review)} />
      </div>
      <div className="text-sm text-brand-link-gray mt-4">{data.comment_content}</div>
      <div className="flex justify-end mt-4 text-sm text-brand-link-gray">{date}</div>
    </div>
  );
};

const LpReviewsCardMock = () => {
  return (
    <div className="text-brand-link-gray flex flex-col mock pb-4 pt-2" style={{ minHeight: 100 }}>
      {/* { Aca va datos del autor} */}
      <div className="flex items-center mt-2 justify-between">
        <div className="flex items-center">
          <div className="rounded-full mr-3 bg-gray-300" style={{ height: 36, width: 36 }} />
          <span className="bg-gray-300 h-5 w-32" />
        </div>
        <span className="bg-gray-300 h-5 w-20" />
      </div>
      <div className="flex flex-col space-y-2 mt-4">
        <span className="bg-gray-300 h-5 w-full" />
        <span className="bg-gray-300 h-5 w-full" />
        <span className="bg-gray-300 h-5 w-full" />
        <span className="bg-gray-300 h-5 w-full" />
      </div>
      <div className="flex justify-end mt-4">
        <span className="bg-gray-300 h-5 w-32" />
      </div>
    </div>
  );
};

function sliceEmail(name) {
  return validateEmail(name) ? name.slice(0, 2) : name;
}

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export default LpReviews;
